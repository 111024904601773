import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/nicktorba/GitHub/nicktorba.com/src/templates/default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p align="center">
     <img src="images/myface.png" style={{
        "width": "200px",
        "height": "300px"
      }} />
    </p>
    <h1 {...{
      "id": "welcome",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#welcome",
        "aria-label": "welcome permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Welcome`}</h1>
    <p>{`I'm Nick Torba. This is my Online Home. `}</p>
    <p>{`With inspiration from many others online, I decided to build `}<a parentName="p" {...{
        "href": "/brain"
      }}>{`[[The Jungle]]`}</a>{`. My goals are to `}</p>
    <ul>
      <li parentName="ul">{`Make connections with others `}</li>
      <li parentName="ul">{`Better understand my ideas through writing`}</li>
      <li parentName="ul">{`Improve my writing and note-taking `}</li>
    </ul>
    <h2 {...{
      "id": "where-to-start",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#where-to-start",
        "aria-label": "where to start permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Where to Start?`}</h2>
    <p>{`Check out `}<a parentName="p" {...{
        "href": "/brain"
      }}>{`[[The Jungle]]`}</a>{`. On that page, I break down of my favorite notes into a few different categories that are great places to start exploring.`}</p>
    <h1 {...{
      "id": "want-to-talk",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#want-to-talk",
        "aria-label": "want to talk permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Want to talk?`}</h1>
    <p>{`Find me on `}<a parentName="p" {...{
        "href": "http://twitter.com/nicktorba"
      }}>{`twitter`}</a>{` and `}<a parentName="p" {...{
        "href": "mailto:nicholastorba@gmail.com"
      }}>{`email`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      